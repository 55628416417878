import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button } from '@components';
import { useGeneralReport } from '@api/reports';
import { getItem } from '@services';
import { IFilters, LocalStorageKey } from '@api/types';

import { ButtonsContainer, ButtonWrapper } from './buttons.styles';
import { CircularProgress } from '@mui/material';

interface IButtonsProps {
  isReportBar: boolean;
  filters: IFilters;
}

export const Buttons = ({ isReportBar, filters }: IButtonsProps) => {
  let params = isReportBar ? filters || {} : getItem<IFilters>(LocalStorageKey.FILTERS);
  const { mutateAsync: getGeneralReport, isLoading } = useGeneralReport();

  const handleGenerateReport = async () => {
      await getGeneralReport(params as IFilters);
  };

  if (!isReportBar) {
    return null;
  }

  return (
    <ButtonsContainer>
      <ButtonWrapper>
      <Button 
          onClick={handleGenerateReport} 
          startIcon={isLoading ? <CircularProgress size={20} /> : <FileDownloadOutlinedIcon />}
          disabled={isLoading}
        >
          {isLoading ? 'Generowanie...' : 'Generuj raport'}
        </Button>
      </ButtonWrapper>
    </ButtonsContainer>
  );
};
